import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`At PCMH Restore Health Center, our mission is simple - to make you feel and stay healthy. Whether you’re coming in for a regular checkup or an urgent treatment, our dedicated team of health practitioners have the skills and resources to take care of your needs.`}</p>
    <p>{`​`}</p>
    <p>{`Our interdisciplinary team aims not only to elevate the health of our patients but also to inculcate lifestyle modifications targeting long term health benefits.`}</p>
    <p>{`PCMH Restore Health is a patient centered medical home which puts the patient at the center of the decision-making process and offers discreet, comprehensive, team-based, coordinated, accessible, high quality, and compassionate care. We believe that a holistic approach is required for optimal and sustainable health. Our services are tailored keeping this in mind.`}</p>
    <p>{`​`}</p>
    <p>{`Our services are wide-ranging: from wellness programs and management of HIV to LGBTQ care and psychological counselling. We at RH, are constantly striving to meets the needs of our patients and to ensure the best quality of care.`}</p>
    <p>{`​`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      